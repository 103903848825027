import { useEffect } from 'react';

// GTMに統合したら削除する
export const GaScriptTag = ({ gaTrackingId }: { gaTrackingId: string }) => {
  useEffect(() => {
    // Google Analytics用の非同期スクリプトタグの追加
    const scriptTag = document.createElement('script');
    scriptTag.src = `https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`;
    scriptTag.async = true;
    document.head.appendChild(scriptTag);

    // Google Analytics設定用のインラインスクリプトの追加
    const inlineScript = document.createElement('script');
    inlineScript.type = 'text/javascript';
    inlineScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${gaTrackingId}');
    `;
    document.head.appendChild(inlineScript);

    return () => {
      document.head.removeChild(scriptTag);
      document.head.removeChild(inlineScript);
    };
  }, [gaTrackingId]);

  return null;
};

export const GtmScriptTag = ({ gtmId }: { gtmId: string }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmId}');
    `;
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, [gtmId]);

  return null;
};

export const GtmNoscriptTag = ({ gtmId }: { gtmId: string }) => (
  <noscript
    dangerouslySetInnerHTML={{
      __html: `
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
          height="0" width="0" style="display:none;visibility:hidden"
        ></iframe>
      `
    }}
  />
);
