import { useCallback, useEffect, useMemo } from 'react';
import type { Exchange, Operation } from 'urql';
import { client } from '~/graphql/client';
import { pipe, map } from 'wonka';
import type { ParsedUrl } from 'query-string';
import { z } from 'zod';
import { useTrackingManager } from './useTrackingManager';

type Props = {
  baseUrl: string;
  requestedUrl: ParsedUrl;
};
const querySchema = z
  .object({
    requestedUrl: z.object({
      url: z.string(),
      query: z.object({
        from: z.union([z.string(), z.array(z.string())]).optional()
      })
    })
  })
  .transform((v) => {
    const from = v.requestedUrl.query.from;
    return from
      ? {
          from: Array.isArray(from) ? from[0] : from
        }
      : null;
  });

export const useGraphqlClient = ({ baseUrl, requestedUrl }: Props) => {
  const { setTracking, trackingRef } = useTrackingManager();
  const mutationInterceptExchange: Exchange = useCallback(
    ({ forward }) =>
      (ops$) => {
        return pipe(
          ops$,
          map((operation: Operation) => {
            if (operation.kind !== 'mutation') {
              return operation;
            }
            return {
              ...operation,
              variables: {
                ...operation.variables,
                tracking: trackingRef.current
              }
            };
          }),
          forward
        );
      },
    [trackingRef]
  );
  const graphqlClient = useMemo(() => {
    return client(baseUrl, mutationInterceptExchange);
  }, [baseUrl, mutationInterceptExchange]);

  useEffect(() => {
    setTracking({
      //上書きの順番はここで指定した順番になる
      ...requestedUrl.query,
      ...querySchema.parse({ requestedUrl })
    });
  }, [setTracking, requestedUrl]);

  return { graphqlClient };
};
